<template>
  <MainLayout
    v-if="pageLayout"
    :blocks="pageLayout.props.blocks"
    :breadcrumbs="pageLayout.breadcrumbs"
    :properties="pageLayout.properties"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { watch } from 'vue';
import {
  ref,
  showError,
  useAsyncData,
  useRoute,
  onBeforeRouteLeave,
  useNuxtApp, useRuntimeConfig
} from '#imports';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { ContentCmsPage, type IContentBlockCms, type IContentCms } from '#sitis/internal/controllers/cms/models/Content';

import { appConfigStore } from '#sitis/stores/modules/app-store';
import type { IBreadcrumbs } from '#sitis/stores/modules/catalog-store';
import useSitisCMSPage from '#sitis/composables/useSitisCMSPage';
import { getImageUrl } from '#sitis/providers/getImageUrl';

interface IExtraMeta {
  name: string;
  content: string;
}

interface IPageLayout {
  breadcrumbs: IBreadcrumbs[];
  props: {
    breadcrumbs: Record<string, string>[];
    blocks: IContentBlockCms[];
  };
  properties?: any;
}

const $route = useRoute();
const { $api } = useNuxtApp();
const cmsStore = cmsContentStore();
const { configList } = appConfigStore();
const pageLayout = ref<IPageLayout | null>();

const path = computed(() => ($route.path === '/' ? 'homepage' : $route.path));

const {
  data: page,
  error,
  pending
} = await useAsyncData(`cms-content-page-${path.value}`, async () => {
  if (path.value !== 'homepage') {
    const $cmsPageData = $route.meta?.pageData as IContentCms;

    return $cmsPageData ?? await cmsStore.getPage({ pageIdOrName: path.value });
  }

  const resp = await $api.agent.get<IContentCms>('/cms/home').then((res) => res._data);

  if (resp) {
    return new ContentCmsPage(resp).toJSON();
  }
}, {
  watch: [() => path.value]
});

watch(() => error.value, () => checkPageError());
watch(() => pending.value, () => checkPageError());

onBeforeRouteLeave((to, from, next) => {
  pageLayout.value = null;
  return next();
});

const initialCmsPage = () => {
  if (!page?.value) {
    pageLayout.value = null;
    return;
  }
  seoHandler(page);

  const pageLayoutInit = ref<IPageLayout | null>({
    props: {
      blocks: [],
      breadcrumbs: []
    },
    breadcrumbs: []
  });

  pageLayoutInit.value!.properties = page.value?.properties;

  const blocks: IContentBlockCms[] = page?.value?.placeholders.content;
  pageLayoutInit.value!.props = {
    blocks,
    breadcrumbs: []
  };

  const pathParts = $route.path.split('/').filter((p) => !!p);

  if (pathParts.length !== 0) {
    pageLayoutInit.value!.breadcrumbs.push({
      title: 'Главная',
      path: '/'
    });

    pageLayoutInit.value!.props.breadcrumbs.push({
      title: 'Главная',
      path: '/'
    });

    const { flatMenuList } = storeToRefs(cmsStore);

    for (const slug of pathParts) {
      const menu = flatMenuList.value.find((m: any) => m.alias === slug);
      if (!menu) {
        continue;
      }

      pageLayoutInit.value!.breadcrumbs.push({
        path: `/${menu.path}`,
        title: menu.title
      });
      pageLayoutInit.value!.props.breadcrumbs.push({
        path: `/${menu.path}`,
        title: menu.title
      });
    }
  }

  pageLayout.value = pageLayoutInit.value;
};
const checkPageError = () => {
  if (pending.value) {
    return;
  }
  if (!error.value) {
    return;
  }
  showError({ statusCode: 404, message: 'Page not found' });
};

const seoHandler = (page: Ref<IContentCms | undefined | null>): void => {
  const extraMeta = initialExtraMeta(configList?.head?.meta);
  const baseUrl = useRuntimeConfig().public.baseApiUrl;
  const image = `${getImageUrl(page?.value?.page?.imageId, 'jpg', baseUrl) || configList?.logoFront || ''}`;

  useSitisCMSPage().setSEOTitles({
    seoTitle: page?.value?.page?.titleTag || page?.value?.page?.title || '',
    seoDescription: page?.value?.page.description || '',
    seoImage: image,
    seoKeywords: page?.value?.page.keywords || '',
    extraMeta
  });
};
const initialExtraMeta = (options: IExtraMeta[] = []) => options.map((t) => ({ name: t.name, content: t.content }));

initialCmsPage();
checkPageError();

definePageMeta({
  middleware: ['layout-setter']
});

</script>
